export const errorMsg = {
    computed: {
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Nieprawidłowy email')
            !this.$v.email.required && errors.push('Email jest wymagany')
            return errors
        },
        nameErrors() {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.required && errors.push('Nazwa jest wymagana')
            return errors
        },
        // clientErrors() {
        //     const errors = []
        //     if (!this.$v.client.$dirty) return errors
        //     !this.$v.client.required && errors.push('Klient jest wymagany')
        //     return errors
        // },
        // copywriterErrors() {
        //     const errors = []
        //     if (!this.$v.copywriter.$dirty) return errors
        //     !this.$v.copywriter.required && errors.push('Stawka jest wymagana')
        //     return errors
        // },
        priceErrors() {
            const errors = []
            if (!this.$v.priceFull.$dirty) return errors
            !this.$v.priceFull.required && errors.push('Stawka jest wymagana')
            !this.$v.priceFull.decimal && errors.push('Nieprawidłowa cena')
            return errors
        },
        priceCopyErrors() {
            const errors = []
            if (!this.$v.priceCopy.$dirty) return errors
			!this.$v.priceCopy.required && errors.push('Stawka jest wymagana')
            !this.$v.priceCopy.decimal && errors.push('Nieprawidłowa cena')
            return errors
        },
        copyRateErrors() {
            const errors = []
            if (!this.$v.copyRate.$dirty) return errors
			!this.$v.copyRate.required && errors.push('Stawka jest wymagana')
            !this.$v.copyRate.decimal && errors.push('Nieprawidłowa cena')
            return errors
        },
        passwordErrors() {
            const errors = []
            if (!this.$v.password.$dirty) return errors
            !this.$v.password.required && errors.push('Hasło jest wymagane')
            return errors
        },

        costErrors() {
            const errors = []
            if (!this.$v.cost.$dirty) return errors
            !this.$v.cost.decimal && errors.push('Nieprawidłowy koszt')
            !this.$v.cost.required && errors.push('Koszt jest wymagany')
            return errors
        },
        characterErrors() {
            const errors = []
            if (!this.$v.characters.$dirty) return errors
            !this.$v.characters.required && errors.push('Liczba znaków jest wymagana')
            !this.$v.characters.decimal && errors.push('Nieprawidłowa liczba znaków')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.decimal && errors.push('Nieprawidłowy numer')
            return errors
        },
    },  
}